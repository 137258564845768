import React from "react"
import styled, { ThemeProvider } from "styled-components"
import { DarkTheme, navbar } from "./Themes"
import Header from "./Header"
import Tesla from "../assets/Images/Tesla.gif"

// import LogoComponent from "../subComponents/LogoComponent";

const Box = styled.div`
  width: 100vw;
  margin-left: 0;
  background: ${(props) => props.theme.body};
  position: relative;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  font-family: Calibre;

  .row {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: 0;

    @media (max-width: 700px) {
      .size {
        width: 350px;
      }
    }
    @media (max-width: 500px) {
      .size {
        width: 250px;
      }
    }
  }

  .row2 {
    display: block;
    justify-content: center;
    align-items: center;
    padding-top: 20vh;
    text-align: center;
    color: white;
    padding-bottom: 10vh;
    .resize-show {
      display: none;
      padding-bottom: 10vh;
    }
    @media (max-width: 570px) {
      .resize-show {
        display: block;
        width: 250px;
        margin-top: -150px;
        padding-top: 0vh;
      }
    }
    @media (max-width: 530px) {
      .resize-show {
        display: block;
        width: 250px;
      }
    }

    @media (max-width: 380px) {
      .resize-show {
        width: 200px;
      }
    }
    @media (max-width: 330px) {
      min-width: 230px;
      .resize-show {
        width: 200px;
      }
    }
  }
  .row2 h1 {
    display: block;
    text-align: left;
    padding-bottom: 10px;
    font-size: 37px;
    @media (max-width: 530px) {
      font-size: 30px;
    }
  }
  .row2 h3 {
    text-align: left;
    font-size: 25px;
    font-weight: 300;
    padding-bottom: 30px;
    @media (max-width: 530px) {
      font-size: 20px;
    }
  }

  .row3 {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 10vh;
    margin-left: 0;

    padding-bottom: 20vh;
    color: white;
    h1 {
      display: block;
      text-align: left;
      padding-bottom: 10px;
      font-size: 37px;
    }
    h3 {
      text-align: left;
      font-size: 25px;
      font-weight: 300;
      padding-bottom: 30px;
    }
    @media (max-width: 1200px) {
      .mobile-size {
        width: 400px;
      }
    }
    @media (max-width: 950px) {
      .my-auto {
        display: none;
      }
      .mobile-size {
        margin-top: -10vh;
      }
    }
    @media (max-width: 500px) {
      .mobile-size {
        width: 300px;

        margin-top: -15vh;
      }
    }
    @media (max-width: 390px) {
      .mobile-size {
        width: 280px;
        margin-left: -10vw;
        margin-top: -15vh;
      }
    }
  }

  .size {
    width: 400px;
    border-radius: 50px;
    border: 2px solid #f8f9fa;
  }
  .mobile-size {
    width: 500px;
  }
  .mobile-size2 {
    width: 300px;
    float: right;
  }
  .workpage-github {
    color: cyan;
  }
  h3 {
    display: block;
  }
`

const Main = styled.div`
  color: ${(props) => props.theme.text};
  z-index: 0;
  font-size: calc(0.6rem + 1vw);
  animation: fadeIn 4s;
  width: 70vw;
  margin-top: 200px;
`

const Vox = () => {
  return (
    <ThemeProvider theme={DarkTheme}>
      <Box theme={navbar}>
        {/* <ParticleComponent theme='dark' /> */}
        <Header />
        <Main>
          <div class="row">
            <img class="size" src={Tesla} alt="Header" />
          </div>
          <div class="row2">
            <div class="col-lg-8 col-sm-10 col-md-10 col-xs-10 mx-auto">
              <h1>Task</h1>
              <h3>
                Mock project to gain more experience with React
                Native. No specific guidelines set by myself
                besides using React Native.
              </h3>
              <h1>Guidelines</h1>
              <h3>
                Free range with this project. Using React Native
                was my only self-assigned requirement. Besides
                that, just following how the actual Tesla website
                looks and sticking to it.
              </h3>
              <h1>Process</h1>
              <h3>
                First step is to break down the Tesla website
                into components that I should create. Few things
                to include, the car list, header, buttons, etc.
              </h3>
              <h1>Solution</h1>
              <h3>
                End result used Expo to build and scale, yarn to
                init, and Android Studio as an emulator.
              </h3>
              <h1>View the GitHub repository</h1>
              <h3>
                <a
                  class="workpage-github"
                  href="https://github.com/SchmaltzVisuals/Tesla-App"
                >
                  Tesla Project GitHub
                </a>
              </h3>
            </div>
          </div>
        </Main>
      </Box>
    </ThemeProvider>
  )
}

export default Vox
