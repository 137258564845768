import React from "react";
import styled from "styled-components";
import myLogo from "../assets/Images/logo.gif";

const Logo = styled.image`

  @media (max-width: 1050px) {
    .size {
      width: 250px;
    }
  }

  @media (max-width: 700px) {
    .size {
      width: 250px;
    }
  }
  @media (max-width: 576) {
    .size {
      width: 250px;
    }
  }

  @media (max-width: 460px) {
    // .size {
    //   width: 550px;
    //   margin-left: 40px;
    //   margin-top: -70vh;
    // }
  }
`;

const LogoComponent = (props) => {
  return (
    <Logo>
      <img class="size" src={myLogo} alt="SiriusVisuals Animated Logo" />
    </Logo>
  );
};

export default LogoComponent;
