import { Col } from "react-bootstrap"
import styled, { ThemeProvider } from "styled-components"
const ATags = styled.div`
  a:hover {
    color: #81f1fd;
  }
  a {
    color: white;
    text-decoration: none;
  }
`
const Main = styled.div`
  @media (max-width: 405px) {
    min-width: 300px;
    margin-left: -30px;
  }
`

export const ProjectCard = ({
  title,
  description,
  imgUrl,
  link,
}) => {
  return (
    <Main>
      <Col size={12} sm={12} md={12} lg={12}>
        <div
          className="proj-imgbx"
          style={{ marginBottom: "40px" }}
        >
          <div className="proj-txtx"></div>
          <a href={link}>
            {" "}
            <img src={imgUrl} />
          </a>

          <br></br>
          <br></br>
          <h4>
            <ATags>
              {" "}
              <a href={link}>{title}</a>
            </ATags>
          </h4>
          <br></br>
          <span>{description}</span>
        </div>
      </Col>
    </Main>
  )
}
