import React from "react"
import styled, { ThemeProvider } from "styled-components"
import { DarkTheme, navbar } from "../Themes"
import Header from "../Header"
import Contact from "../Contact"
import DynaHome from "../../assets/Images/webDev/Dyna/dynahome.png"
// import LogoComponent from "../subComponents/LogoComponent";

const Box = styled.div`
  position: relative;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  font-family: Calibre;
  text-align: center;
  align-content: center;
  h1 {
    font-size: 60px;
  }
  p {
    font-size: 25px;
    color: white;
  }
  .content {
    justify-content: center;
    width: 60%;
    margin: auto;
  }
`

const Main = styled.div`
  color: ${(props) => props.theme.text};
  z-index: 0;
  animation: fadeIn 4s;
  img {
    width: 80%;
  }
  margin-top: 200px;
`

const Dynatrace = () => {
  return (
    <ThemeProvider theme={DarkTheme}>
      <Header />
      <Box theme={navbar}>
        <Main>
          <div class="content">
            {" "}
            <h1>Dynatrace</h1>
            <p>
              The purpose of this project was to redesign
              Dynatrace's website to instantly showcase the
              features of the product. Rather than consumers
              having to do more research on what the product is
              for, they will have a general idea within a few
              seconds of scrolling.
            </p>
          </div>

          <img src={DynaHome} alt="" />
        </Main>
      </Box>
    </ThemeProvider>
  )
}

export default Dynatrace
