import { motion } from "framer-motion"
import React from "react"
import styled, { ThemeProvider } from "styled-components"
import { DarkTheme, navbar } from "./Themes"
import DTE from "../assets/Images/azure.png"
import demo_project from "../assets/Images/teslaPreview.jpg"
import OU from "../assets/Images/ou_traffic.png"
import AWSCRC from "../assets/Images/awscrc.png"
import { NavLink } from "react-router-dom"
import fresno from "../assets/Images/fresno.png"
import Human from "../assets/Images/humanocity.png"
import { Github } from "./AllSvgs"
const Box = styled.div`
  background-color: ${(props) => props.theme.body};
  height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Roboto', sans-serif;
  padding-top: 108rem;
.padding-left{
  padding-left: 10px;
}
  #no-padding {
    padding-top: 0px;
  }
  .techstack {
    display: block;
  }
  .techstack a{
    font-size: 15px;
  }
  .techstack ul{
    flex-wrap: wrap;
    display: flex;
  }
  #view-project-right {
    padding-right: 0px;
    font-size: 15px;
    float: right;
  }
  #view-project-left{
    font-size: 15px;
    float: left;
  }

  #view-project-left a{
    padding: 0;
  }
  img {
    
    width: 100%;
  }




  .portfolio-img {
    width: 580px;
    height: 365px;
    border-radius: 5px;
    z-index: 0;
    position: relative;
    background: linear-gradient(to top, #81f1fd, #81f1fd);
    @media (max-width: 991.5px){
      display: flex;
      justify-content: center;
      margin: 0;
      background: linear-gradient(to top, #00337A, #00337A);
    }
  }

  #github-project-link {
    float: right;
  }



  .portfolio-img img {
    width: 580px;
    height: 365px;
    border-radius: 5px;
    opacity: 0.4;
    z-index: 0;
    transition: all 0.3s ease;
    @media (max-width: 991.5px){
      opacity: 0.05;
    }
    
  }

  .portfolio-img img:hover {
    border-radius: 5px;

    opacity: 1;
    z-index: 0;
    transition: all 0.3s ease;
    @media (max-width: 991.5px){
      opacity: 0.05;
    }
  }
  .line {
    border: 1px solid red;
  }
  
  svg{
    display: block;
    margin-left: 0rem;
    float: left;
    padding: 0;
  }

  .section-heading {
    display: flex;
    width: 100%:
    display: inline;
    padding-bottom: 2rem;
    color: #81F1FD;
   
  }

.section-heading h1{
  font-weight: 500;
 
}

  .section-heading h1:after{
    content: "";
    display: block;
    position: relative;
    top: -15px;
    width: 300px;
    height: 1px;
    margin-left: 230px;
    background-color: grey;
  }

  .portfolio-description{
    padding: 15px;
    margin-top: -20px;
    @media (max-width: 991.5px){
      position: absolute;
      z-index: 1;
      text-align: left;
      opacity: 1;
      width: 50vw;
      padding-left: 0;
      height: 300px;
      p{
        font-size: 17px;
      }
      #right-side{
        text-align: left;
        margin-left: 0;
        padding: 0;

      }
      .left {
        margin-right: 0;
        margin-left: 0;
        padding: 0;
      }
      .right{
        margin-left: 0;
        padding: 0;
      }
      .techstack {
        margin-right: 0;
        margin-left: 0;
        padding-left: 0;
        float: left;
      }
      .techstack li{
        padding-left: 0;
        padding-right: 1rem;
      }
    }
    @media (max-width: 730px){
      width: 70vw;
    }
 
  }

  .portfolio-description p {
    color: white;
    
  }


.techstack {
  padding-top: 1rem;
  float: right;
}
.techstack a{
  color: #81F1FD;
  text-decoration: none;
  transition: 0.2s;
  padding-left: 10px;

}

.techstack a:hover{
  color: white;
  transition: 0.2s;


}

.techstack svg:hover{
    stroke: #ffffff;

}
#left{
  float: left;
  
}


#github-right-side{
float: right;
}

#github-right-side a{
  padding-left: 0px;
}

#left li{
  padding-left: 0rem;
  padding-right: 1rem;
}
  .techstack li{
    display: inline;
    color: white;
    font-size: 14px;
    padding-left: 2rem;
  }

  .portfolio-description h6{
    font-family: Calibre;
    color: #81F1FD;
  }
  #right-side{
    text-align: right;
    width: 100%;
  }
  .background-box{
    background-color: #125D9C;
    display: flex;
    padding: 20px 10px 10px 20px;
    text-align: left;
    justify-content: center;
    align-items: center;
    z-index: 5;
    opacity: 1;
    color: #bfbfbf;
    position: relative;
    box-sizing: inhert;
    border-radius: 5px;
    box-shadow: 2px 2px 5px #001b41;
    @media (max-width: 991.5px){
        background-color: transparent;
        border: 0px;
        box-shadow: none;
        
    }

  }

  .left{
    margin-right: -10%;
    @media (max-width: 1050px){
      margin-right: -35% ;
    }

  }
  .right{
    margin-left: -10%;
    @media (max-width: 1050px)
      margin-left: -35% ;
    }

  }

  @media (max-width: 1300px){
    margin-top: 10vh;
  }
  @media (max-width: 1050px){
    margin-top: 15vh;
  }
  @media (max-width: 991.5px){
    margin-top: 0vh;
    #view-project-right{
      float: left;
    }
    .techstack a{
      padding-left: 0;
    }
    #view-project-left{
      padding-right: 10px;
    }
    #github-right-side{
      float: left;
      padding-left: 0;
      padding-right: 10px;
      }
    .portfolio-img img{
      height: 365px;
    }
    .third{
      .portfolio-description {
        padding-top: 50px;
      }
      .portfolio-img img{
        height: 300px;
      }
      .portfolio-img{
        height: 300px;
     }
   }
  }
  @media (max-width: 768px){
    .container {
      max-width: 100%;
    }
  }
  @media (max-width: 610px){
    margin-top: 25vh;
    .section-heading h1{
      font-size: 25px;
    }
    .section-heading h1:after{
      margin-left: 175px;
      width: 250px;
      top: -13px;
    }
    .portfolio-img {
      width: 500px;
      height: 500px;
    }

    .third{
      .portfolio-img img{
        height: 450px;
      }
      .portfolio-img{
        height: 450px;
     }
   }

    .portfolio-img img{
      width: 500px;
      height: 500px;
    }
    .portfolio-description{
      padding: 0;
    }
  }

  @media (max-width: 570px){
    margin-top: 30vh;


  }

  @media (max-width: 550px){
    margin-top: 20vh;
    .portfolio-img {
      width: 400px;
      height: 450px;
    }
    .techstack li{
      padding-left: 0.5;
    }
    #left li{
      padding-left: 0rem;
      padding-right: 0.5rem;
    }
    .portfolio-img img{
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: 460px){
    margin-top: 40vh;
    .portfolio-img {
      flex: 0.9;
      height: 550px;
    }
    .section-heading h1:after{
      top: -15px;
      width: 170px;
      height: 1px;
      margin-left: 170px;
      background-color: grey;
      
    }

    .techstack{
 width: 50vw;
    }

    .section-heading h1{
      font-size: 22px;
    }
.section-heading{
  padding-left: 1.5rem;
}
    #left li{
      padding-left: 0rem;
      padding-right: 0.5rem;
    }
  }

  @media (max-width: 420px){
    .section-heading h1:after{
      top: -15px;
      width: 0px;
      height: 1px;
      margin-left: 170px;
      background-color: grey;
    }


    .section-heading{
      display: flex;
      justify-content: center;
    }
    margin-top: 70vh;
    .portfolio-img {
      flex: 0.9;
      height: 650px;
    }
    .third{
      .portfolio-description{
        margin-top: -20vh;
      }
      .portfolio-img {
        height: 550px;
      }
      .portfolio-img img{
        height: 550px;
      }
    } 
    .fourth{
      .portfolio-description{
        margin-top: -5vh;
      }
      .portfolio-img {
        height: 500px;
      }
      .portfolio-img img{
        height: 500px;
      }
    }
    .portfolio-description{
      margin-top: -20vh;
    }
    .fifth{
      .portfolio-description{
        margin-top: -10vh;
      }
      .portfolio-img {
        height: 650px;
      }
      .portfolio-img img{
        height: 650px;
      }
      .portfolio-description{
        margin-top: -20vh;
      }
    }

    

    @media (max-width: 305px){
      .portfolio-img{
       
      }
      .portfolio-img img{
 
      }
      .portfolio-description{
        width: 220px;
      }
      ul{
        width: 200px;
      }
    }
    
  }


`

const DTE_LINK = styled(NavLink)`
  color: ${(props) => props.theme.text};
`
const OU_LINK = styled(NavLink)`
  color: ${(props) => props.theme.text};
`
const TESLA_LINK = styled(NavLink)`
  color: ${(props) => props.theme.text};
`
const FRESNO_LINK = styled(NavLink)`
  color: ${(props) => props.theme.text};
`
const HUMANOCITY_LINK = styled(NavLink)`
  color: ${(props) => props.theme.text};
`

const WorkPage = () => {
  return (
    <ThemeProvider theme={DarkTheme}>
      <Box theme={navbar}>
        <section class="portfolio" id="portfolio">
          <div class="container">
            <div class="section-heading">
              <h1>A few Projects</h1>
            </div>

            <div class="portfolio-item">
              <div class="portfolio-description">
                <h6>AWS x Dynatrace Cloud Resume Challenge</h6>
                <div class="background-box left">
                  {" "}
                  <p>
                    The CloudResumeChallenge was fun to go
                    through and helped me gain practical AWS use.
                    I put a twist on this challenge by
                    integrating Dynatrace for synthetic
                    monitoring of my website. This is one of the
                    many projects I'll complete as I work moving
                    into a cloud-focused/site-reliability role.
                  </p>
                </div>
                <div class="techstack" id="left">
                  {" "}
                  <ul>
                    <li>DynamoDB</li>
                    <li>CodePipeline</li>
                    <li>CloudFront</li>
                    <li>CloudWatch</li>
                    <li>Lambda</li>
                    <li>S3</li>
                    <li>Route53</li>
                  </ul>
                  <motion.h2
                    id="view-project-left"
                    initial={{
                      y: -200,
                      transition: {
                        type: "spring",
                        duration: 1.5,
                        delay: 1,
                      },
                    }}
                    animate={{
                      y: 0,
                      transition: {
                        type: "spring",
                        duration: 1.5,
                        delay: 1,
                      },
                    }}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                  >
                    <a href="https://github.com/SchmaltzVisuals/AWS-Dynatrace-CloudResumeChallenge/blob/main/README.md">
                      <Github width={20} height={20} />
                    </a>
                  </motion.h2>
                  <motion.h2
                    initial={{
                      y: -200,
                      transition: {
                        type: "spring",
                        duration: 1.5,
                        delay: 1,
                      },
                    }}
                    animate={{
                      y: 0,
                      transition: {
                        type: "spring",
                        duration: 1.5,
                        delay: 1,
                      },
                    }}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 1.1 }}
                  >
                    <OU_LINK id="view-project-left" to="/AWSCRC">
                      View Project
                    </OU_LINK>
                  </motion.h2>
                </div>
              </div>

              <div class="portfolio-img">
                <OU_LINK class="project-header" to="/AWSCRC">
                  <img src={AWSCRC} alt="Project Header" />
                </OU_LINK>
              </div>
            </div>

            <div class="portfolio-item first">
              <div class="portfolio-img">
                <DTE_LINK
                  class="project-header"
                  to="/AzureSentinel"
                >
                  <img src={DTE} alt="Project Header" />
                </DTE_LINK>
              </div>
              <div class="portfolio-description">
                <h6 id="right-side">
                  Live Attack Map - Azure Sentinel
                </h6>
                <div class="background-box right">
                  {" "}
                  <p>
                    In this project, I set up an Azure Virtual
                    Machine to act as a honeypot and disabled all
                    security measures to allow my VM to be picked
                    up by vulnerability scanners. This live map
                    is tracking in real time all of the attempted
                    logins to my VM from attackers.
                  </p>
                </div>
                <div class="techstack">
                  <ul>
                    <li>Azure VM</li>
                    <li>PowerShell</li>
                    <li>API</li>
                    <li>Azure Sentinel</li>
                    <li>Azure Logs</li>
                  </ul>

                  <motion.h2
                    id="view-project-right"
                    initial={{
                      y: -200,
                      transition: {
                        type: "spring",
                        duration: 1.5,
                        delay: 1,
                      },
                    }}
                    animate={{
                      y: 0,
                      transition: {
                        type: "spring",
                        duration: 1.5,
                        delay: 1,
                      },
                    }}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 1.1 }}
                  >
                    <DTE_LINK to="/AzureSentinel">
                      View Project
                    </DTE_LINK>
                    <a href="https://github.com/SchmaltzVisuals/AzureSentinelLiveAttackMap">
                      <Github width={20} height={20} />
                    </a>
                  </motion.h2>
                </div>
              </div>
            </div>

            <div class="portfolio-item">
              <div class="portfolio-description">
                <h6>Oakland University Traffic</h6>
                <div class="background-box left">
                  {" "}
                  <p>
                    This project was during my Software
                    Engineering course and all we were given was
                    that our project should serve some sort of
                    useful purpose. OU Traffic is used to get
                    live updates on how busy a specific facility
                    is at OU. For example, we cover parking lots,
                    dining options, recreation, and study spots.
                    The way it works is by using a report based
                    system.
                  </p>
                </div>
                <div class="techstack" id="left">
                  {" "}
                  <ul>
                    <li>Bootstrap</li>
                    <li>HTML</li>
                    <li>CSS</li>
                    <li>PHP</li>
                    <li>SCSS</li>
                    <li>Bitnami</li>
                    <li>JavaScript</li>
                  </ul>
                  <motion.h2
                    id="view-project-left"
                    initial={{
                      y: -200,
                      transition: {
                        type: "spring",
                        duration: 1.5,
                        delay: 1,
                      },
                    }}
                    animate={{
                      y: 0,
                      transition: {
                        type: "spring",
                        duration: 1.5,
                        delay: 1,
                      },
                    }}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                  >
                    <a href="https://github.com/SchmaltzVisuals/ou-campus-status">
                      <Github width={20} height={20} />
                    </a>
                  </motion.h2>
                  <motion.h2
                    initial={{
                      y: -200,
                      transition: {
                        type: "spring",
                        duration: 1.5,
                        delay: 1,
                      },
                    }}
                    animate={{
                      y: 0,
                      transition: {
                        type: "spring",
                        duration: 1.5,
                        delay: 1,
                      },
                    }}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 1.1 }}
                  >
                    <OU_LINK
                      id="view-project-left"
                      to="/OUTraffic"
                    >
                      View Project
                    </OU_LINK>
                  </motion.h2>
                </div>
              </div>

              <div class="portfolio-img">
                <OU_LINK class="project-header" to="/OUTraffic">
                  <img src={OU} alt="Project Header" />
                </OU_LINK>
              </div>
            </div>

            <div class="portfolio-item third">
              <div class="portfolio-img">
                <TESLA_LINK class="project-header" to="/Tesla">
                  <img src={demo_project} alt="Project Header" />
                </TESLA_LINK>
              </div>

              <div class="portfolio-description">
                <h6 id="right-side">Tesla</h6>
                <div class="background-box right">
                  {" "}
                  <p>
                    This is just a practice project working with
                    React Native. I used Expo to build this as
                    well as Android Studio to view the mobile
                    result as I worked. The image on the left is
                    just a placeholder as I can't place the
                    mobile view beings the dimensions are too
                    small.
                  </p>
                </div>
                <div class="techstack">
                  <ul>
                    <li>JavaScript</li>
                    <li>Android Studio</li>
                    <li>React Native</li>
                    <li>Expo</li>
                    <li>Yarn</li>
                  </ul>
                  <motion.h2
                    id="github-right-side"
                    class="padding-left"
                    initial={{
                      y: -200,
                      transition: {
                        type: "spring",
                        duration: 1.5,
                        delay: 1,
                      },
                    }}
                    animate={{
                      y: 0,
                      transition: {
                        type: "spring",
                        duration: 1.5,
                        delay: 1,
                      },
                    }}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                  >
                    <a href="https://github.com/SchmaltzVisuals/Tesla-App">
                      <Github width={20} height={20} />
                    </a>
                  </motion.h2>
                  <motion.h2
                    id="view-project-right"
                    initial={{
                      y: -200,
                      transition: {
                        type: "spring",
                        duration: 1.5,
                        delay: 1,
                      },
                    }}
                    animate={{
                      y: 0,
                      transition: {
                        type: "spring",
                        duration: 1.5,
                        delay: 1,
                      },
                    }}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 1.1 }}
                  >
                    <TESLA_LINK to="/Tesla">
                      View Project
                    </TESLA_LINK>
                  </motion.h2>
                </div>
              </div>
            </div>

            <div class="portfolio-item fourth">
              <div class="portfolio-description">
                <h6>Fresno Ski Shop</h6>
                <div class="background-box left">
                  {" "}
                  <p>
                    Fresno Ski & Lessons Shop was created during
                    my database class. Rather than focusing on
                    the UI, we were more focused on the backend.
                    This was my introduction to working with SQL.
                    Users are able to schedule lessons, rent
                    items, and purchase items.
                  </p>
                </div>
                <div class="techstack" id="left">
                  {" "}
                  <ul>
                    <li>Bootstrap</li>
                    <li>HTML</li>
                    <li>CSS</li>
                    <li>PHP</li>
                    <li>SQL</li>
                    <li>Bitnami</li>
                  </ul>
                  <motion.h2
                    id="view-project-left"
                    initial={{
                      y: -200,
                      transition: {
                        type: "spring",
                        duration: 1.5,
                        delay: 1,
                      },
                    }}
                    animate={{
                      y: 0,
                      transition: {
                        type: "spring",
                        duration: 1.5,
                        delay: 1,
                      },
                    }}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                  >
                    <a href="https://github.com/SchmaltzVisuals/fresno-ski-resort">
                      <Github width={20} height={20} />
                    </a>
                  </motion.h2>
                  <motion.h2
                    initial={{
                      y: -200,
                      transition: {
                        type: "spring",
                        duration: 1.5,
                        delay: 1,
                      },
                    }}
                    animate={{
                      y: 0,
                      transition: {
                        type: "spring",
                        duration: 1.5,
                        delay: 1,
                      },
                    }}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 1.1 }}
                  >
                    <FRESNO_LINK
                      id="view-project-left"
                      to="/Fresno"
                    >
                      View Project
                    </FRESNO_LINK>
                  </motion.h2>
                </div>
              </div>

              <div class="portfolio-img">
                <FRESNO_LINK class="project-header" to="/Fresno">
                  <img src={fresno} alt="Project Header" />
                </FRESNO_LINK>
              </div>
            </div>

            <div class="portfolio-item fifth">
              <div class="portfolio-img">
                <HUMANOCITY_LINK
                  class="project-header"
                  to="/Humanocity"
                >
                  <img src={Human} alt="Project Header" />
                </HUMANOCITY_LINK>
              </div>
              <div class="portfolio-description">
                <h6 id="right-side">Humanocity</h6>
                <div class="background-box right">
                  {" "}
                  <p>
                    Humanocity is an application that could be
                    used to successfully manage the employees of
                    a company. The application serves as a
                    database where HR managers can view employee
                    information and edit it as needed. HR
                    managers would want to use this application
                    to keep a company and its employees organized
                    in an accessible location.
                  </p>
                </div>
                <div class="techstack">
                  <ul>
                    <li>React</li>
                    <li>JavaScript</li>
                    <li>HTML</li>
                    <li>CSS</li>
                    <li>JSON</li>
                    <li>Java</li>
                  </ul>
                  <motion.h2
                    id="github-right-side"
                    class="padding-left"
                    initial={{
                      y: -200,
                      transition: {
                        type: "spring",
                        duration: 1.5,
                        delay: 1,
                      },
                    }}
                    animate={{
                      y: 0,
                      transition: {
                        type: "spring",
                        duration: 1.5,
                        delay: 1,
                      },
                    }}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                  >
                    <a href="https://github.com/SchmaltzVisuals/humanocity-client">
                      <Github width={20} height={20} />
                    </a>
                  </motion.h2>
                  <motion.h2
                    id="github-right-side"
                    initial={{
                      y: -200,
                      transition: {
                        type: "spring",
                        duration: 1.5,
                        delay: 1,
                      },
                    }}
                    animate={{
                      y: 0,
                      transition: {
                        type: "spring",
                        duration: 1.5,
                        delay: 1,
                      },
                    }}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 1.1 }}
                  >
                    <HUMANOCITY_LINK
                      id="view-project-right"
                      to="/Humanocity"
                    >
                      View Project
                    </HUMANOCITY_LINK>
                  </motion.h2>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Box>
    </ThemeProvider>
  )
}

export default WorkPage
