import React from "react"
import styled, { ThemeProvider } from "styled-components"
import { DarkTheme, navbar } from "./Themes"
import Header from "./Header"
import DteProject from "../assets/Images/azure.png"
import syn1 from "../assets/Images/synthetic1.png"
import syn2 from "../assets/Images/synthetic2.png"
import syn3 from "../assets/Images/synthetic3.png"
import awscrc from "../assets/Images/awscrc.png"
import cloudwatch from "../assets/Images/cloudwatch.png"

// import LogoComponent from "../subComponents/LogoComponent";

const Box = styled.div`
  position: relative;
  overflow-x: hidden;
  display: flex;
  width: 100vw;
  justify-content: center;
  font-family: Calibre;
  .row {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: 0;

    @media (max-width: 1500px) {
      .size {
        width: 900px;
      }
    }

    @media (max-width: 1200px) {
      .size {
        width: 800px;
      }
    }
    @media (max-width: 900px) {
      .size {
        width: 650px;
      }
    }

    @media (max-width: 700px) {
      .size {
        width: 550px;
      }
    }
    @media (max-width: 570px) {
      display: none;
    }
  }

  .row2 {
    display: block;
    justify-content: center;
    align-items: center;
    padding-top: 20vh;
    text-align: center;
    color: white;
    padding-bottom: 10vh;
    .resize-show {
      display: none;
    }
    @media (max-width: 570px) {
      .resize-show {
        display: block;
        width: 250px;
        margin-top: -250px;
        padding-top: 0vh;
      }
    }
    @media (max-width: 530px) {
      .resize-show {
        display: block;
        width: 250px;
      }
    }

    @media (max-width: 380px) {
      .resize-show {
        width: 200px;
      }
    }
    @media (max-width: 330px) {
      min-width: 230px;
      .resize-show {
        width: 200px;
      }
    }
  }
  .row2 h1 {
    display: block;
    text-align: left;
    padding-bottom: 10px;
    font-size: 37px;
    @media (max-width: 530px) {
      font-size: 30px;
    }
  }
  .row2 h3 {
    text-align: left;
    font-size: 25px;
    font-weight: 300;
    padding-bottom: 30px;
    @media (max-width: 530px) {
      font-size: 20px;
    }
  }

  .row3 {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 10vh;
    margin-left: 0;
    padding-bottom: 20vh;
    color: white;
    h1 {
      display: block;
      text-align: left;
      padding-bottom: 10px;
      font-size: 37px;
    }
    h3 {
      text-align: left;
      font-size: 25px;
      font-weight: 300;
      padding-bottom: 30px;
    }
    @media (max-width: 1200px) {
      .mobile-size {
        width: 400px;
      }
    }
    @media (max-width: 950px) {
      .my-auto {
        display: none;
      }
      .mobile-size {
        margin-top: -10vh;
      }
    }
    @media (max-width: 500px) {
      .mobile-size {
        width: 300px;

        margin-top: -15vh;
      }
    }
    @media (max-width: 390px) {
      .mobile-size {
        width: 280px;
        margin-left: -5vw;
        margin-top: -15vh;
      }
    }
  }

  .size {
    width: 1100px;
    border-radius: 50px;
    border: 2px solid #f8f9fa;
  }
  .mobile-size {
    width: 500px;
  }
  .mobile-size2 {
    width: 300px;
    float: right;
  }

  .workpage-github {
    color: cyan;
  }
  h3 {
    display: block;
  }
`

const Main = styled.div`
  color: ${(props) => props.theme.text};
  z-index: 0;
  font-size: calc(0.6rem + 1vw);
  animation: fadeIn 4s;
  width: 70vw;
  margin-top: 200px;
`

const AWSCRC = () => {
  return (
    <ThemeProvider theme={DarkTheme}>
      <Header />
      <Box theme={navbar}>
        <Main>
          <div class="row">
            <img
              class="size"
              src={awscrc}
              alt="screenshot of app"
            />
          </div>
          <div class="row2">
            <div class="col-lg-8 col-sm-10 col-md-10 col-xs-10 mx-auto">
              <img
                class="resize-show mx-auto"
                src={awscrc}
                alt="screenshot of app"
              />
              <h1>Task</h1>
              <h3>
                The CloudResumeChallenge was fun to go through
                and helped me gain practical AWS use. I put a
                twist on this challenge by integrating Dynatrace
                for synthetic monitoring of my website. This is
                one of the many projects I'll complete as I work
                moving into a cloud-focused/site-reliability
                role.
              </h3>
              <h1>Guidelines</h1>
              <h3>
                Use CloudFront, CloudWatch, S3, Lambda, DynamoDB,
                Route53, CodePipeline, and Certificate Manager to
                create an AWS infrastructure which logs when
                someone visits the dedicated site
              </h3>
              <h1>Process</h1>
              <h3>
                Overall the challenge involved: Setting up a
                hosted zone through 53 Routing traffic to
                CloudFront Enabling SSL/TLS with Certificate
                Manager (enables an encrypted connection to my
                website) Storing my website files in an S3 Bucket
                CloudFront is linked to my Bucket in order to
                display the website. Lambda is used for display
                the website. Lambda is used for triggering the
                event of incrementing website view count in
                DynamoDB every time my site is visited (when a
                user navigates to my site, a JS function is run
                automatically which triggers my lambda function)
              </h3>
              <h1>Result</h1>
              <h3>
                Result is a live view of attempted log ins. I'm
                displaying the country along with the IP address
                of those who tried to log in to this VM. The
                delay for synthetic events from Sydney Australia
                is due to my CloudFront distribution only being
                hosted in North America and Europe as this is the
                cheapest option. Utilizing AWS edge locations
                would solve the delay but is the highest price
                class. Overall, this was a great "practical use
                case" project.
              </h3>
              <h1>View the GitHub repository</h1>
              <h3>
                <a
                  class="workpage-github"
                  href="https://github.com/SchmaltzVisuals/AWS-Dynatrace-CloudResumeChallenge/blob/main/README.md"
                >
                  AWS-Dynatrace-CRC GitHub
                </a>
              </h3>
            </div>
          </div>
        </Main>
      </Box>
    </ThemeProvider>
  )
}

export default AWSCRC
