import React from "react";
import styled, { ThemeProvider } from "styled-components";
import { DarkTheme, navbar } from "./Themes";

import LogoComponent from "../subComponents/LogoComponent";

const Box = styled.div`
  width: 100vw;
  margin-left: 0;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  .no-show{
    display: none;
  }
  .row {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 1000px;
    @media (max-width: 991.5px) {
      width: 800px;
      margin-left: -3vw;
  }
    @media (max-width: 767.5px) {
      margin-left: -18vw;
    }
    @media (max-width: 700px) {
      margin-left: -23vw;
    }
    @media (max-width: 576px) {
      display: inline;
      justify-content: center;
      align-items: center;
      margin: auto;
      width: 400px;
      .no-show{
        display: inherit;
        margin-left: 13px;
      }
      .remove{
        display: none;
      }
      .col{
        padding: 30px;
        width: 300px;
      }


    }
  
  }


`;

const Main = styled.div`
  color: ${(props) => props.theme.text};
  z-index: 0;
  font-size: calc(0.6rem + 1vw);
  animation: fadeIn 4s;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 1000px;

`;

const Heading = styled.h1`
  font-family: ${(props) => props.theme.fontFamily};
  font-size: 30px;
  display: block;
  margin-left: 20%;
  h1 {
    font-family: "aquaticoregular";
    display: block;
    padding: 0;
  }

  @media (max-width: 1050px) {
    font-size: 23px;
    margin-left: 45%;
  }


  @media (max-width: 991.5px) {
    margin-left: 30%;
    
  }
  @media (max-width: 700px) {
    margin-left: 40%;
    
  }
  @media (max-width: 576px) {
    text-align: center;
    margin: auto;
  }


  h3 {
    font-size: 20px;
  }
`;

const Intro = () => {
  return (
    <ThemeProvider theme={DarkTheme}>
      <Box theme={navbar}>
        {/* <ParticleComponent theme='dark' /> */}

        <Main>
          <div class="container">
            <div class="row">
            <div class="col no-show">
                <LogoComponent />
              </div>
              <div class="col">
                <Heading styles={styled.h1}>
                  <h1>Envision.</h1>
                  <h1>Create.</h1>
                  <h1>Inspire.</h1>
                </Heading>
              </div>
              <div class="col remove">
                <LogoComponent />
              </div>
            </div>
          </div>
        </Main>
      </Box>
    </ThemeProvider>
  );
};

export default Intro;
