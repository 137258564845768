import React from "react"
import styled, { ThemeProvider } from "styled-components"
import { DarkTheme, navbar } from "./Themes"
import Header from "./Header"
import stacked_mobile from "../assets/Images/humanocity-stacked.png"
import first_mobile from "../assets/Images/humanocity-single.png"
import header from "../assets/Images/humanocity.png"

// import LogoComponent from "../subComponents/LogoComponent";

const Box = styled.div`
  width: 100vw;
  margin-left: 0;
  background: ${(props) => props.theme.body};
  position: relative;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  font-family: Calibre;

  .row {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: 0;
    @media (max-width: 1500px) {
      .size {
        width: 900px;
      }
    }
    @media (max-width: 1200px) {
      .size {
        width: 800px;
      }
    }
    @media (max-width: 900px) {
      .size {
        width: 650px;
      }
    }
    @media (max-width: 830px) {
    }

    @media (max-width: 700px) {
      .size {
        width: 550px;
      }
    }
    @media (max-width: 570px) {
      .size {
        display: none;
      }
    }
  }

  .row2 {
    display: block;
    justify-content: center;
    align-items: center;
    padding-top: 20vh;
    text-align: center;
    color: white;
    padding-bottom: 10vh;
    .resize-show {
      display: none;
      padding-bottom: 10vh;
    }
    @media (max-width: 570px) {
      .resize-show {
        display: block;
        width: 250px;
        margin-top: -200px;
        padding-top: 0vh;
      }
    }
    @media (max-width: 530px) {
      .resize-show {
        display: block;
        width: 250px;
      }
    }

    @media (max-width: 380px) {
      .resize-show {
        width: 200px;
      }
    }
    @media (max-width: 330px) {
      min-width: 230px;
      .resize-show {
        width: 200px;
      }
    }
  }
  .row2 h1 {
    display: block;
    text-align: left;
    padding-bottom: 10px;
    font-size: 37px;
    @media (max-width: 530px) {
      font-size: 30px;
    }
  }
  .row2 h3 {
    text-align: left;
    font-size: 25px;
    font-weight: 300;
    padding-bottom: 30px;
    @media (max-width: 530px) {
      font-size: 20px;
    }
  }

  .row3 {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 10vh;
    margin-left: 0;

    padding-bottom: 20vh;
    color: white;
    h1 {
      display: block;
      text-align: left;
      padding-bottom: 10px;
      font-size: 37px;
    }
    h3 {
      text-align: left;
      font-size: 25px;
      font-weight: 300;
      padding-bottom: 30px;
    }
    @media (max-width: 1200px) {
      .mobile-size {
        width: 400px;
      }
    }
    @media (max-width: 950px) {
      .my-auto {
        display: none;
      }
      .mobile-size {
        margin-top: -10vh;
      }
    }
    @media (max-width: 500px) {
      .mobile-size {
        width: 300px;

        margin-top: -15vh;
      }
    }
    @media (max-width: 390px) {
      .mobile-size {
        width: 280px;
        margin-left: -15vw;
        margin-top: -15vh;
      }
    }
  }

  .size {
    width: 1100px;
    border-radius: 50px;
    border: 2px solid #f8f9fa;
  }
  .mobile-size {
    width: 500px;
  }
  .mobile-size2 {
    width: 300px;
    float: right;
  }
  .workpage-github {
    color: cyan;
  }
  h3 {
    display: block;
  }
`

const Main = styled.div`
  color: ${(props) => props.theme.text};
  z-index: 0;
  font-size: calc(0.6rem + 1vw);
  animation: fadeIn 4s;
  width: 70vw;
  margin-top: 200px;
`

const Humanocity = () => {
  return (
    <ThemeProvider theme={DarkTheme}>
      <Header />
      <Box theme={navbar}>
        {/* <ParticleComponent theme='dark' /> */}
        <Main>
          <div class="row">
            <img
              class="size"
              src={header}
              alt="screenshot of app"
            />
          </div>
          <div class="row2">
            <div class="col-lg-8 col-sm-10 col-md-10 col-xs-10 mx-auto">
              <img
                class="resize-show mx-auto"
                src={first_mobile}
                alt="screenshot of app"
              />
              <h1>Task</h1>
              <h3>
                Project given during my Sophomore Project course.
                No strict guidelines either than create an
                application that tests our knowledge of CS
                concepts. Sticking with my preference for
                front-end/full-stack work, I ended up joining a
                group that shared the same preferences.
              </h3>
              <h1>Guidelines</h1>
              <h3>
                The class was fairly laid back. All we had to do
                was come up with a concept project, share basic
                details with our professor and he would approve
                or deny it. If the project seemed too easy and
                wasn't going to be a challenge, he would
                challenge us to up the difficulty.
              </h3>
              <h1>Process</h1>
              <h3>
                For Humanocity, our main focus was getting our
                backend to work with the front-end. We used
                firebase to store data such as salaries, our
                "employee's" names, addresses, etc.
              </h3>
              <h1>Solution</h1>
              <h3>
                End result used Figma for designing and HTML,
                CSS, React, JS, and Java. Firebase as our
                database.
              </h3>
              <h1>View the GitHub repository</h1>
              <h3>
                <a
                  class="workpage-github"
                  href="https://github.com/SchmaltzVisuals/humanocity-client"
                >
                  Humanocity GitHub
                </a>
              </h3>
            </div>
          </div>
          <div class="row3">
            <div class="col-sm-10 col-md-10 col-lg-7 ">
              <img
                class="mobile-size"
                src={stacked_mobile}
                alt="screenshot of app"
              />
            </div>
            <div class="col-md-6 my-auto">
              <h1>Mobile View</h1>
              <h3>
                Just to practice responsive design, always
                important to ensure the site works no matter the
                device width.
              </h3>
            </div>
          </div>
        </Main>
      </Box>
    </ThemeProvider>
  )
}

export default Humanocity
