// Here we will create dark and light theme variables

export const navbar = {
  body: "#001430",
  text: "#81F1FD",
  fontFamily: "'Fira Mono', monospace",
  bodyRgba: "252, 246, 244",
  textRgba: "0,0,0",
};

export const DarkTheme = {
  body: "#ffffff",
  text: "#81F1FD",
  fontFamily: "'Inter', sans-serif",
  textRgba: "252, 246, 244",
  bodyRgba: "0,0,0",
};

export const headings = {
  fontFamily: "'Open Sans', sans-serif",
  text: "#ffffff",
};
