import React from "react"
import styled, { ThemeProvider } from "styled-components"
import { DarkTheme, navbar } from "./Themes"
import Header from "./Header"
import DteProject from "../assets/Images/azure.png"
import dte1 from "../assets/Images/dte-combined.png"
import dte3 from "../assets/Images/dte-mobile.png"
// import LogoComponent from "../subComponents/LogoComponent";

const Box = styled.div`
  position: relative;
  overflow-x: hidden;
  display: flex;
  width: 100vw;
  justify-content: center;
  font-family: Calibre;
  .row {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: 0;

    @media (max-width: 1500px) {
      .size {
        width: 900px;
      }
    }

    @media (max-width: 1200px) {
      .size {
        width: 800px;
      }
    }
    @media (max-width: 900px) {
      .size {
        width: 650px;
      }
    }

    @media (max-width: 700px) {
      .size {
        width: 550px;
      }
    }
    @media (max-width: 570px) {
      display: none;
    }
  }

  .row2 {
    display: block;
    justify-content: center;
    align-items: center;
    padding-top: 20vh;
    text-align: center;
    color: white;
    padding-bottom: 10vh;
    .resize-show {
      display: none;
    }
    @media (max-width: 570px) {
      .resize-show {
        display: block;
        width: 250px;
        margin-top: -250px;
        padding-top: 0vh;
      }
    }
    @media (max-width: 530px) {
      .resize-show {
        display: block;
        width: 250px;
      }
    }

    @media (max-width: 380px) {
      .resize-show {
        width: 200px;
      }
    }
    @media (max-width: 330px) {
      min-width: 230px;
      .resize-show {
        width: 200px;
      }
    }
  }
  .row2 h1 {
    display: block;
    text-align: left;
    padding-bottom: 10px;
    font-size: 37px;
    @media (max-width: 530px) {
      font-size: 30px;
    }
  }
  .row2 h3 {
    text-align: left;
    font-size: 25px;
    font-weight: 300;
    padding-bottom: 30px;
    @media (max-width: 530px) {
      font-size: 20px;
    }
  }

  .row3 {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 10vh;
    margin-left: 0;
    padding-bottom: 20vh;
    color: white;
    h1 {
      display: block;
      text-align: left;
      padding-bottom: 10px;
      font-size: 37px;
    }
    h3 {
      text-align: left;
      font-size: 25px;
      font-weight: 300;
      padding-bottom: 30px;
    }
    @media (max-width: 1200px) {
      .mobile-size {
        width: 400px;
      }
    }
    @media (max-width: 950px) {
      .my-auto {
        display: none;
      }
      .mobile-size {
        margin-top: -10vh;
      }
    }
    @media (max-width: 500px) {
      .mobile-size {
        width: 300px;

        margin-top: -15vh;
      }
    }
    @media (max-width: 390px) {
      .mobile-size {
        width: 280px;
        margin-left: -5vw;
        margin-top: -15vh;
      }
    }
  }

  .size {
    width: 1100px;
    border-radius: 50px;
    border: 2px solid #f8f9fa;
  }
  .mobile-size {
    width: 500px;
  }
  .mobile-size2 {
    width: 300px;
    float: right;
  }
  .workpage-github {
    color: cyan;
  }
  h3 {
    display: block;
  }
`

const Main = styled.div`
  color: ${(props) => props.theme.text};
  z-index: 0;
  font-size: calc(0.6rem + 1vw);
  animation: fadeIn 4s;
  width: 70vw;
  margin-top: 200px;
`

const DTE = () => {
  return (
    <ThemeProvider theme={DarkTheme}>
      <Header />
      <Box theme={navbar}>
        <Main>
          <div class="row">
            <img
              class="size"
              src={DteProject}
              alt="screenshot of app"
            />
          </div>
          <div class="row2">
            <div class="col-lg-8 col-sm-10 col-md-10 col-xs-10 mx-auto">
              <img
                class="resize-show mx-auto"
                src={dte3}
                alt="screenshot of app"
              />
              <h1>Task</h1>
              <h3>
                Create a honeypot using Azure a Virtual Machine.
                I'll be tracking any attempted connections + log
                ins to the VM.
              </h3>
              <h1>Guidelines</h1>
              <h3>
                Toolset is PowerShell, Azure, Azure Sentinel,
                Azure Log Analytics Workspace, and the API
                ipgeolocation.
              </h3>
              <h1>Process</h1>
              <h3>
                After creating the virtual machine in Azure, I
                needed to disable security measures through
                Microsoft Defender as well as the firewall on the
                VM. After doing that, create a custom query with
                log analytics workspace. Then connect to the
                virtual machine, run the powershell script with
                the API token from ipgeolocation.io to grab
                coordinates of those who try to log in to my VM.
                Log analytics workspace will be used to query
                this data and then in Azure Sentinel, I will have
                a workspace for this map.
              </h3>
              <h1>Result</h1>
              <h3>
                Result is a live view of attempted log ins. I'm
                displaying the country along with the IP address
                of those who tried to log in to this VM.
              </h3>
              <h1>View the GitHub repository</h1>
              <h3>
                <a
                  class="workpage-github"
                  href="https://github.com/SchmaltzVisuals/AzureSentinelLiveAttackMap"
                >
                  AzureSentinel Honeypot GitHub
                </a>
              </h3>
            </div>
          </div>
        </Main>
      </Box>
    </ThemeProvider>
  )
}

export default DTE
