
import React from "react";
import styled from "styled-components";


import WorkPage from "./WorkPage";
import Intro from "./Intro";
import Header from "./Header";



const MainContainer = styled.div`
  background: ${(props) => props.theme.body};
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  position: relative;

    --scroll-behavior: smooth;
      scroll-behavior: smooth;
  h2,
  h3,
  h4,
  h5,

`;



const Container = styled.div`
  // padding-bottom: 22rem;
`;

// const Numbers = styled.h4`
//   color: ${(props) => props.theme.text};
//   padding-right: 5px;
//   font-size: 10px;
// `;

const Main = () => {


  return (
    <MainContainer>
      <Container>
        <div className="progressbar"></div>
        <div className="scrollpath"></div>

        <Header/>
        {/* <Intro/> */}

        <WorkPage/>
      </Container>
    </MainContainer>
  );
};

export default Main;
