import React from "react"
import styled, { ThemeProvider } from "styled-components"
import { DarkTheme, navbar } from "../Themes"
import Header from "../Header"
import Contact from "../Contact"
import BigApple from "../../assets/Images/webDev/Apple/bigApple.png"
// import LogoComponent from "../subComponents/LogoComponent";

const Box = styled.div`
  position: relative;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  font-family: Calibre;
  text-align: center;
  align-content: center;
  h1 {
    font-size: 60px;
  }
  p {
    font-size: 25px;
    color: white;
  }
  .content {
    justify-content: center;
    width: 60%;
    margin: auto;
  }
`

const Main = styled.div`
  color: ${(props) => props.theme.text};
  z-index: 0;
  animation: fadeIn 4s;
  img {
    width: 80%;
  }
  margin-top: 200px;
`

const Dynatrace = () => {
  return (
    <ThemeProvider theme={DarkTheme}>
      <Header />
      <Box theme={navbar}>
        <Main>
          <div class="content">
            {" "}
            <h1>Apple</h1>
            <p>
              Apple is known for their creativity so the best way
              to show Apple is to show vibrant and fun colors.
              The site is full of animations and an animated
              background but this is just a screenshot.
            </p>
          </div>

          <img src={BigApple} alt="" />
        </Main>
      </Box>
    </ThemeProvider>
  )
}

export default Dynatrace
