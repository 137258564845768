import { Route, Switch } from "react-router"
import { ThemeProvider } from "styled-components"
import { navbar } from "./components/Themes"
import GlobalStyle from "./globalStyles"

//Components
import Main from "./components/Main"
import AboutPage from "./components/AboutPage"
import AzureSentinel from "./components/AzureSentinel"
import Dynatrace from "./components/websites/Dynatrace"
import Apple from "./components/websites/Apple"
import OUTraffic from "./components/OUTraffic"
import Tesla from "./components/Tesla"
import Humanocity from "./components/Humanocity"
import Fresno from "./components/Fresno"
import WorkPage from "./components/WorkPage"
import AWSCRC from "./components/AWS-CRC"
import Webdesign from "./components/Webdesign"
import { AnimatePresence } from "framer-motion"
import Contact from "./components/Contact"
import NotFoundPage from "./components/NotFoundPage"
function App() {
  // const location = useLocation();
  return (
    <>
      <GlobalStyle />

      <ThemeProvider theme={navbar}>
        {/* For framer-motion animation on page change! */}
        <AnimatePresence exitBeforeEnter>
          <Switch>
            <Route exact path="/" component={Main} />
            <Route exact path="/Contact" component={Contact} />
            <Route
              exact
              path="/AzureSentinel"
              component={AzureSentinel}
            />
            <Route
              exact
              path="/Dynatrace"
              component={Dynatrace}
            />
            <Route exact path="/Apple" component={Apple} />
            <Route
              exact
              path="/OUTraffic"
              component={OUTraffic}
            />
            <Route exact path="/Tesla" component={Tesla} />
            <Route exact path="/AWSCRC" component={AWSCRC} />
            <Route exact path="/Fresno" component={Fresno} />
            <Route
              exact
              path="/Humanocity"
              component={Humanocity}
            />
            <Route exact path="/About" component={AboutPage} />
            <Route exact path="/Projects" component={WorkPage} />
            <Route
              exact
              path="/WebDesign"
              component={Webdesign}
            />
            <Route path="*" component={NotFoundPage} />
          </Switch>
        </AnimatePresence>
      </ThemeProvider>
    </>
  )
}

export default App
