import React from "react"
import styled, { ThemeProvider } from "styled-components"
import { DarkTheme, navbar } from "./Themes"
import Header from "./Header"
import C1 from "../assets/Images/concept1.png"
import C2 from "../assets/Images/concept2.png"
import C3 from "../assets/Images/concept3.png"
import OUF1 from "../assets/Images/OUFinal1.png"
import OUF2 from "../assets/Images/OUFinal2.png"
import OUF3 from "../assets/Images/OUFinal3.png"

import OU from "../assets/Images/ou_traffic.png"

// import LogoComponent from "../subComponents/LogoComponent";

const Box = styled.div`
  width: 100vw;
  margin-left: 0;

  background: ${(props) => props.theme.body};
  position: relative;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  font-family: Calibre;

  .row {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: 0;
    @media (max-width: 1500px) {
      .size {
        margin-left: 10%;
        width: 900px;
      }
    }
    @media (max-width: 1200px) {
      .size {
        width: 700px;
      }
    }

    @media (max-width: 900px) {
      .size {
        width: 650px;
      }
    }

    @media (max-width: 755px) {
      .size {
        width: 500px;
      }
    }
    @media (max-width: 570px) {
      display: none;
    }
  }

  .row2 {
    display: block;
    justify-content: center;
    align-items: center;
    padding-top: 20vh;
    text-align: center;
    color: white;
    padding-bottom: 10vh;
    .resize-show {
      display: none;
    }
    @media (max-width: 570px) {
      .resize-show {
        display: block;
        width: 250px;
        margin-top: -150px;
        padding-bottom: 10vh;
      }
    }
    @media (max-width: 540px) {
      .resize-show {
        display: block;
        width: 250px;
      }
    }
    @media (max-width: 530px) {
      .resize-show {
        display: block;
        width: 250px;
      }
    }

    @media (max-width: 380px) {
      .resize-show {
        width: 200px;
      }
    }
    @media (max-width: 330px) {
      min-width: 230px;
      .resize-show {
        width: 200px;
      }
    }
  }
  .row2 h1 {
    display: block;
    text-align: left;
    padding-bottom: 10px;
    font-size: 37px;
    @media (max-width: 530px) {
      font-size: 30px;
    }
  }
  .row2 h3 {
    text-align: left;
    font-size: 25px;
    font-weight: 300;
    padding-bottom: 30px;
    @media (max-width: 530px) {
      font-size: 20px;
    }
  }

  .row3 {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 10vh;
    margin-left: 0;
    padding-bottom: 10vh;
    color: white;
    h1 {
      display: block;
      text-align: left;
      padding-bottom: 10px;
      font-size: 37px;
    }
    h3 {
      text-align: left;
      font-size: 25px;
      font-weight: 300;
      padding-bottom: 30px;
    }
    @media (max-width: 1200px) {
      .mobile-size {
        width: 400px;
      }
    }
    @media (max-width: 950px) {
      .mobile-size {
        margin-top: -10vh;
      }
    }
    @media (max-width: 810px) {
      h1 {
        margin-left: 7vw;
      }
    }
    @media (max-width: 630px) {
      h1 {
        margin-left: 10vw;
      }
    }
    @media (max-width: 540px) {
      h1 {
        margin-left: 5vw;
      }
    }

    @media (max-width: 390px) {
      h1 {
        font-size: 30px;
      }
    }
    @media (max-width: 335px) {
      min-width: 230px;
    }
    @media (max-width: 300px) {
      h1 {
        margin-left: -3vw;
      }
    }
  }
  .row4 {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 0vh;
    margin-left: 0;
    padding-bottom: 20vh;
    color: white;
    .mobile-size {
      width: 300px;
    }
    @media (max-width: 1000px) {
      .mobile-size {
        width: 230px;
      }
    }
    @media (max-width: 810px) {
      display: block;
      .mobile-size {
        margin-left: 20vw;
        padding-bottom: 10vh;
        margin-top: -5vh;
        width: 210px;
      }
    }
    @media (max-width: 540px) {
      .mobile-size {
        width: 270px;
        margin-left: 8vw;

        margin-top: -5vh;
      }
    }
    @media (max-width: 450px) {
      .mobile-size {
        width: 270px;
        margin-left: auto;
      }
    }
    @media (max-width: 400px) {
      .mobile-size {
        width: 250px;
        margin-left: auto;
      }
    }
    @media (max-width: 390px) {
      .mobile-size {
        width: 210px;
        margin-left: auto;
      }
    }
    @media (max-width: 300px) {
      .mobile-size {
        margin-left: -3vw;
      }
    }
  }
  .row5 {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 0vh;
    margin-left: 0;
    padding-bottom: 10vh;
    color: white;

    h1 {
      font-size: 37px;
    }
    .mobile-size {
      width: 300px;
    }
    @media (max-width: 700px) {
      h1 {
        margin-left: 5vw;
      }
    }
    @media (max-width: 600px) {
      h1 {
        margin-left: 10vw;
      }
    }
    @media (max-width: 540px) {
      h1 {
        margin-left: 5vw;
      }
    }
    @media (max-width: 450px) {
      h1 {
        margin-left: 0vw;
      }
    }
    @media (max-width: 400px) {
      h1 {
        margin-left: -7vw;
        font-size: 30px;
      }
      min-width: 270px;
    }
    @media (max-width: 300px) {
      h1 {
        margin-left: -21vw;
      }
    }
  }
  .row6 {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 0vh;
    padding-bottom: 20vh;
    color: white;
    .mobile-size2 {
      width: 310px;
    }
    @media (max-width: 1000px) {
      .mobile-size2 {
        width: 250px;
      }
    }
    @media (max-width: 810px) {
      display: block;
      .mobile-size2 {
        margin-left: 15vw;
        padding-bottom: 10vh;
        margin-top: -5vh;
      }
    }
    @media (max-width: 540px) {
      .mobile-size2 {
        width: 290px;
        margin-left: 5vw;

        margin-top: -5vh;
      }
    }
    @media (max-width: 450px) {
      .mobile-size2 {
        width: 290px;
        margin-left: -2vw;

        margin-top: -5vh;
      }
    }
    @media (max-width: 400px) {
      .mobile-size2 {
        width: 230px;
        margin-left: -3vw;

        margin-top: -5vh;
      }
    }
    @media (max-width: 300px) {
      .mobile-size2 {
        margin-left: -8vw;
      }
    }
  }
  .size {
    width: 1100px;
    border-radius: 50px;
    border: 2px solid #f8f9fa;
  }
  .mobile-size {
    width: 500px;
  }
  .mobile-size2 {
    width: 350px;
  }
  .workpage-github {
    color: cyan;
  }
  h3 {
    display: block;
  }
`

const Main = styled.div`
  color: ${(props) => props.theme.text};
  z-index: 0;
  font-size: calc(0.6rem + 1vw);
  animation: fadeIn 4s;
  width: 70vw;
  margin-top: 200px;
`

const OUTraffic = () => {
  return (
    <ThemeProvider theme={DarkTheme}>
      <Box theme={navbar}>
        {/* <ParticleComponent theme='dark' /> */}
        <Header />
        <Main>
          <div class="row">
            <img class="size" src={OU} alt="header" />
          </div>
          <div class="row2">
            <div class="col-lg-8 col-sm-10 col-md-10 col-xs-10 mx-auto">
              <img
                class="resize-show mx-auto"
                src={C1}
                alt="mobile header"
              />
              <h1>Task</h1>
              <h3>
                Our task was to design something useful. After
                brainstorming for a short duration, we decided to
                take a shot at solving an issue regarding our
                campus. That issue is traffic. We wanted to
                monitor parking lots, food courts, recreation,
                and study spots.
              </h3>
              <h1>Guidelines</h1>
              <h3>
                The only requirement given is that our project
                must serve a useful purpose. Basically take a
                known issue and give our take on better
                solutions.
              </h3>
              <h1>Process</h1>
              <h3>
                The biggest obstacle was getting a map of the
                university and ensuring we could maintain the
                quality on resize. To do this, I ended up taking
                70 sections of the map and combining them in
                photoshop. Now users can zoom in/resize/etc and
                the quality will still be good. We basically
                divided this project into UI/UX and
                functionality. I designed concepts via Figma to
                make the coding process easier. Splitting up
                tasks into sprints, we had 5 of us to cruise
                through this project. Also we were required to
                make reports to show our progress and include use
                cases, class diagrams, sequence diagrams, etc.
              </h3>
              <h1>Solution</h1>
              <h3>
                The end result was simpler than my concept
                designs as UX was important to us. If we went
                with my initial concept design, the user
                experience would have suffered greatly on mobile.
                The biggest requirement was for our users to be
                able to move around the map and see as much as
                possible. The bottom navbar constrained that
                goal.
              </h3>
              <h1>View the GitHub repository</h1>
              <h3>
                <a
                  class="workpage-github"
                  href="https://github.com/SchmaltzVisuals/ou-campus-status"
                >
                  Oakland University Traffic GitHub
                </a>
              </h3>
            </div>
          </div>
          <div class="row3">
            <div class="mx-auto">
              <h1>Design Concepts</h1>
            </div>
          </div>
          <div class="row4 mx-auto">
            <div class="col-xl-3 col-lg-4">
              <img
                class="mobile-size"
                src={C2}
                alt="screenshot of app"
              />
            </div>
            <div class="col-xl-3 col-lg-4">
              <img
                class="mobile-size"
                src={C3}
                alt="screenshot of app"
              />
            </div>
            <div class="col-xl-3 col-lg-4">
              <img
                class="mobile-size"
                src={C1}
                alt="screenshot of app"
              />
            </div>
          </div>
          <div class="row5">
            <div class="mx-auto">
              <h1>Final Product</h1>
            </div>
          </div>
          <div class="row6 mx-auto">
            <div class="col-xl-3 col-lg-4">
              <img
                class="mobile-size2"
                src={OUF1}
                alt="screenshot of app"
              />
            </div>
            <div class="col-xl-3 col-lg-4">
              <img
                class="mobile-size2"
                src={OUF2}
                alt="screenshot of app"
              />
            </div>
            <div class="col-xl-3 col-lg-4">
              <img
                class="mobile-size2"
                src={OUF3}
                alt="screenshot of app"
              />
            </div>
          </div>
        </Main>
      </Box>
    </ThemeProvider>
  )
}

export default OUTraffic
