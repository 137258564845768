import React from "react"
import styled, { ThemeProvider } from "styled-components"
import { navbar, DarkTheme } from "./Themes"
import Header from "./Header"
import Resume from "../assets/Images/LinkedInResume.pdf"

const Box = styled.div`
  background-color: ${(props) => props.theme.body};
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow-x: hidden;
`

const Main = styled.div`
  color: ${(props) => props.theme.text};
  padding: 2rem;
  z-index: 3;
  line-height: 1.5;
  font-size: 1.5rem;
  font-family: "Inter", sans-serif;
  font-family: Calibre;
  animation: fadeIn 4.5s;
  .row {
    margin-top: 18vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .size {
    width: 550px;
    height: auto;
    border-radius: 20px;
  }
  .about-me h1 {
    font-size: 35px;

    padding-bottom: 3rem;
  }
  .about-me p {
    font-size: 23px;
    font-weight: 400;
    padding-bottom: 1rem;
  }
  .about-me {
    width: 500px;
    margin-top: 3rem;
    padding-left: 2rem;
  }
  @media (max-width: 1099px) {
    .about-me {
      width: 600px;
    }
  }
  @media (max-width: 650px) {
    .size {
      width: 100%;
      min-width: 280px;
    }
    .about-me {
      margin-left: -15px;
    }
  }
`

const AboutPage = () => {
  return (
    <ThemeProvider theme={DarkTheme}>
      <Box theme={navbar}>
        {/* <LogoComponent theme='dark'/> */}
        <Header></Header>

        <Main>
          <div class="row">
            <div class="about-me">
              <h1>
                I'm currently working at Magna International as a Systems Engineer. Formerly worked at Ford, DTE, and Plastic Omnium. 
              </h1>

              <p>
              As a Systems Engineer at Magna International, I specialize in creating applications with PowerApps and designing automations that streamline operations, a role that perfectly blends my interests in application development and process efficiency. My tenure at Ford Motor Company as a Systems Engineer was pivotal, focusing on server monitoring with Dynatrace and process automation with PowerAutomate, which solidified my foundational knowledge and skills in system optimization and reliability.
<br></br> 
Earlier in my career as a Software Engineer at DTE Energy, I engaged with various tools, with a particular focus on PowerApps, PowerAutomate, SharePoint, JavaScript, HTML, and CSS. This experience was instrumental in building my experience  in software development, further fueling my enthusiasm for technology and innovation.
<br></br> 
My journey through these roles has been driven by an interest in cloud engineering, where I aim to leverage my diverse background to architect, deploy, and manage scalable and efficient cloud infrastructures. I am committed to continuous learning and applying cutting-edge technologies to solve complex challenges, always with an eye toward enhancing business value and operational excellence.




              </p>
            </div>
          </div>
        </Main>
      </Box>
    </ThemeProvider>
  )
}

export default AboutPage
