import React from "react"
import styled, { ThemeProvider } from "styled-components"
import { navbar } from "./Themes"
import Header from "./Header.js"
import { Github, LinkedIn } from "./AllSvgs"
import { motion } from "framer-motion"
import { NavLink } from "react-router-dom"

const Box = styled.div`
  background-color: ${(props) => props.theme.body};

  position: relative;
`

const Main = styled.div`
  color: ${(props) => props.theme.text};

  padding: 2rem;
  width: 500px;
  height: 300px;
  z-index: 3;
  line-height: 1.5;

  font-family: "Roboto", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;

  text-align: center;
  @media (max-height: 500px) {
    min-height: 500px;
    margin-top: 100px;
  }
  .effect:hover {
    stroke: #ffffff;
  }

  p {
    color: white;
  }
  .right {
    margin-left: 50px;
    margin-right: 50px;
  }

  .inner-container {
    padding-bottom: 40px;
    h1 {
      padding-bottom: 20px;
      animation-duration: 2s;
      animation-name: slideUp;
    }
    p {
      padding-bottom: 10px;
    }
    .email {
      margin-top: 8px;
    }
  }

  .button-57 {
    position: relative;
    overflow: hidden;
    border: 1px solid white;
    color: white;
    border-radius: 10px;
    display: inline-block;
    font-size: 15px;
    line-height: 15px;
    padding: 18px 18px 17px;
    text-decoration: none;
    cursor: pointer;
    background-color: #001430;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }

  .button-57 span:first-child {
    position: relative;
    transition: color 600ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 10;
  }

  .button-57 span:last-child {
    color: black;
    display: block;
    position: absolute;
    bottom: 0;
    transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 100;
    opacity: 0;
    top: 50%;
    left: 50%;
    transform: translateY(225%) translateX(-50%);
    height: 14px;
    line-height: 13px;
  }

  .button-57:after {
    content: "";
    position: absolute;
    bottom: -50%;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid white;
    background-color: #81f1fd;
    transform-origin: bottom center;
    transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    transform: skewY(9.3deg) scaleY(0);
    z-index: 50;
  }

  .button-57:hover:after {
    transform-origin: bottom center;
    transform: skewY(9.3deg) scaleY(2);
  }

  .button-57:hover span:last-child {
    transform: translateX(-50%) translateY(-100%);
    opacity: 1;
    transition: all 900ms cubic-bezier(0.48, 0, 0.12, 1);
  }

  @media (max-width: 480px) {
    .right {
      margin: 10px;
    }

    width: 100vw;
    max-width: 100vw;
  }
  @media (max-width: 315px) {
    min-width: 300px;
  }
`

const Contact = () => {
  return (
    <ThemeProvider theme={navbar}>
      <Header></Header>
      <Box>
        <Main>
          <div class="container">
            <div class="inner-container">
              <h1>Contact</h1>
              <p>
                I'm open to full-time opportunities, either remote or local to Metro Detroit. Feel free to
                message me on LinkedIn or email me by clicking
                the contact button. If you're viewing this on a
                desktop, right click the contact button and click
                open link in new tab to get my email.{" "}
              </p>
              <a
                rel="noreferrer"
                class="button-57"
                role="button"
                href="mailto:schmaltzbusiness@gmail.com"
                target="_blank"
              >
                <span class="text">Contact</span>
                <span class="email">Email</span>
              </a>
            </div>

            <motion.h2
              initial={{
                y: -1500,
                transition: {
                  type: "spring",
                  duration: 1.5,
                  delay: 0.95,
                },
              }}
              animate={{
                y: 0,
                transition: {
                  type: "spring",
                  duration: 1.5,
                  delay: 0.95,
                },
              }}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              <NavLink
                style={{ color: "inherit" }}
                target="_blank"
                to={{
                  pathname: "https://github.com/SchmaltzVisuals",
                }}
              >
                <Github
                  class="effect right"
                  width={20}
                  height={20}
                />
              </NavLink>
            </motion.h2>
            <motion.h2
              initial={{
                y: -1500,
                transition: {
                  type: "spring",
                  duration: 1.5,
                  delay: 1.05,
                },
              }}
              animate={{
                y: 0,
                transition: {
                  type: "spring",
                  duration: 1.5,
                  delay: 1.05,
                },
              }}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              <NavLink
                style={{ color: "inherit" }}
                target="_blank"
                to={{
                  pathname:
                    "https://www.linkedin.com/in/taylenschmaltz/",
                }}
              >
                <LinkedIn
                  class="effect right"
                  width={20}
                  height={20}
                />
              </NavLink>
            </motion.h2>
          </div>
        </Main>
      </Box>
    </ThemeProvider>
  )
}

export default Contact
