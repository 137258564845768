import React from "react"
import styled, { ThemeProvider } from "styled-components"
import { navbar } from "./Themes"
import Header from "./Header.js"
import Contact from "./Contact"
import { Container, Row, Col, Tab, Nav } from "react-bootstrap"

import { ProjectCard } from "./ProjectCard"
import projImg1 from "../assets/Images/webDev/Dyna/dyna-home.png"
import projImg2 from "../assets/Images/webDev/Apple/MainPage.png"

// import projImg2 from "../assets/Images/dyna-home.png"
// import projImg3 from "../assets/Images/dyna-home.png"
import TrackVisibility from "react-on-screen"

const Box = styled.div`
  background-color: ${(props) => props.theme.body};
  img {
    width: 100%;
  }
  position: relative;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  animation: fadeIn 4s;
`

const Main = styled.div`
  color: ${(props) => props.theme.text};

  padding: 2rem;
  width: 80vw;

  margin-top: 200px;
  z-index: 3;
  line-height: 1.5;
  h5 {
    display: block;
    font-family: Calibre;
    padding-bottom: 20px;
  }
  h2 {
    font-family: Calibre;
    display: block;
    text-align: center;
  }

  font-family: "Roboto", sans-serif;
`

const ContactWrap = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  text-align: center;
`

const Experience = () => {
  const projects = [
    {
      title: "Apple",
      description: "Landing page design and build.",
      imgUrl: projImg2,
      link: "/Apple",
    },
    {
      title: "Dynatrace",
      description: "Landing page design and build.",
      imgUrl: projImg1,
      link: "/Dynatrace",
    },
    // {
    //   title: "Business Startup",
    //   description: "Design & Development",
    //   imgUrl: projImg3,
    //   link: "google.com",
    // },
    // {
    //   title: "Business Startup",
    //   description: "Design & Development",
    //   imgUrl: projImg1,
    //   link: "google.com",
    // },
    // {
    //   title: "Business Startup",
    //   description: "Design & Development",
    //   imgUrl: projImg2,
    //   link: "google.com",
    // },
    // {
    //   title: "Business Startup",
    //   description: "Design & Development",
    //   imgUrl: projImg3,
    //   link: "google.com",
    // },
  ]
  return (
    <ThemeProvider theme={navbar}>
      <Header></Header>
      <Box>
        <Main>
          <section className="project" id="project">
            <Container>
              <Row>
                <Col size={12}>
                  <TrackVisibility>
                    {({ isVisible }) => (
                      <div
                        className={
                          isVisible
                            ? "animate__animated animate__fadeIn"
                            : ""
                        }
                      >
                        <h2>Freelance Work</h2>
                        {/* <p>
    
                        </p> */}
                        <Tab.Container
                          id="projects-tabs"
                          defaultActiveKey="first"
                        >
                          <Nav
                            variant="pills"
                            className="nav-pills mb-5 justify-content-center align-items-center"
                            id="pills-tab"
                          >
                            {/* <Nav.Item>
                              <Nav.Link eventKey="first">
                                Tab 1
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="second">
                                Tab 2
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="third">
                                Tab 3
                              </Nav.Link>
                            </Nav.Item> */}
                          </Nav>
                          <Tab.Content
                            id="slideInUp"
                            className={
                              isVisible
                                ? "animate__animated animate__slideInUp"
                                : ""
                            }
                          >
                            <Tab.Pane eventKey="first">
                              <Row>
                                {projects.map(
                                  (project, index) => {
                                    return (
                                      <ProjectCard
                                        key={index}
                                        {...project}
                                      />
                                    )
                                  }
                                )}
                              </Row>
                            </Tab.Pane>
                            {/* <Tab.Pane eventKey="second">
                              <p>
                                Lorem ipsum dolor sit amet
                                consectetur adipisicing elit.
                                Cumque quam, quod neque provident
                                velit, rem explicabo excepturi id
                                illo molestiae blanditiis,
                                eligendi dicta officiis
                                asperiores delectus quasi
                                inventore debitis quo.
                              </p>
                            </Tab.Pane>
                            <Tab.Pane eventKey="third">
                              <p>
                                Lorem ipsum dolor sit amet
                                consectetur adipisicing elit.
                                Cumque quam, quod neque provident
                                velit, rem explicabo excepturi id
                                illo molestiae blanditiis,
                                eligendi dicta officiis
                                asperiores delectus quasi
                                inventore debitis quo.
                              </p>
                            </Tab.Pane> */}
                          </Tab.Content>
                        </Tab.Container>
                      </div>
                    )}
                  </TrackVisibility>
                </Col>
              </Row>
            </Container>
            <img className="background-image-right"></img>
          </section>
        </Main>
      </Box>
      <ContactWrap>
        <Contact></Contact>
      </ContactWrap>
    </ThemeProvider>
  )
}

export default Experience
